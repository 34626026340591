import React from "react"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <div className="relative">
      <StaticImage
        src="../images/doraopt-home.jpg"
        alt="gatsby astronaut"
        className="w-full"
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 ">
        <div className="text-center ">
          <h1 className="text-white text-9xl">404</h1>
          <Link to="/" className="text-white text-3xl hover:text-gray-200">
            回到首页
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
